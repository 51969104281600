<template>
  <div class="SalMonth">
    月末处理
  </div>
</template>

<script>
export default {
  name: 'SalMonth',
  data () {
    return {

    };
  },
  methods: {}
}
</script>

<style scoped>

</style>