<template>
  <div class="SalSob" style="width:1400px">
    <div class="SalBtn">
      <el-button type="primary" icon="el-icon-plus" @click="showAddSalaryView">添加工资账套</el-button>
      <el-button type="success" icon="el-icon-refresh" @click="initSalaries"></el-button>
    </div>
    <div style="margin-top:10px;">
      <el-table
        v-loading="loading"
        element-loading-text="信息加载中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)" 
        :data="salaries"
        border
        stripe>
        <el-table-column type="selection" width="40" align="center"></el-table-column>
        <el-table-column label="账套名称" prop="name" width="120" align="center"></el-table-column>
        <el-table-column label="基本工资" prop="basicSalary" width="70" align="center"></el-table-column>
        <el-table-column label="交通补助" prop="trafficSalary" width="70" align="center"></el-table-column>
        <el-table-column label="午餐补助" prop="lunchSalary" width="70" align="center"></el-table-column>
        <el-table-column label="奖金" prop="bonus" width="70" align="center"></el-table-column>
        <el-table-column label="启用时间" prop="createDate" width="100" align="center"></el-table-column>
        <el-table-column label="养老金" align="center">
          <el-table-column label="比率" prop="pensionPer" width="70" align="center"></el-table-column>
          <el-table-column label="基数" prop="pensionBase" width="70" align="center"></el-table-column>
        </el-table-column>
        <el-table-column label="医疗保险" align="center">
          <el-table-column label="比率" prop="medicalPer" width="70" align="center"></el-table-column>
          <el-table-column label="基数" prop="medicalBase" width="70" align="center"></el-table-column>
        </el-table-column>
        <el-table-column label="公积金" align="center">
          <el-table-column label="比率" prop="accumulationFundPer" width="70" align="center"></el-table-column>
          <el-table-column label="基数" prop="accumulationFundBase" width="70" align="center"></el-table-column>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="primary" align="center" @click="showEditSalView(scope.row)">编辑</el-button>
            <el-button type="danger" align="center" @click="deleteSal(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        width="50%">
      <div class="showAddSal">
          <el-steps direction="vertical" :active="activeItemIndex">
            <el-step :title="itemName" v-for="(itemName,index) in salaryItemNames" :key="index"></el-step>
          </el-steps>
          <el-input
            v-model="salary[title]" 
            :placeholder="'请输入'+salaryItemNames[index]+'...'" 
            v-for="(value,title,index) in salary" 
            :key="index"
            v-show="activeItemIndex==index"
            style="width:300px"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="preStep">{{activeItemIndex==10?'取 消':'上一步'}}</el-button>
        <el-button type="primary" @click="nextStep">{{activeItemIndex==10?'提 交':'下一步'}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'SalSob',
  data () {
    return {
      loading:false,
      dialogTitle:'',
      dialogVisible:false,
      activeItemIndex:0,
      salaries:[],
      salaryItemNames:[
        '账套名称',
        '基本工资',
        '交通补助',
        '午餐补助',
        '奖金',
        '养老金比率',
        '养老金基数',
        '医疗保险比率',
        '医疗保险基数',
        '公积金比率',
        '公积金基数'
      ],
      salary:{
        name:'',
        basicSalary:0,
        trafficSalary:0,
        lunchSalary:0,
        bonus:0,
        pensionPer:0,
        pensionBase:0,
        medicalPer:0,
        medicalBase:0,
        accumulationFundPer:0,
        accumulationFundBase:0
      }
    };
  },
  mounted(){
    this.initSalaries();
  },
  methods: {
    showEditSalView(data){
      this.dialogTitle = "编辑工资账套";
      this.activeItemIndex = 0;
      this.dialogVisible = true;
      this.salary.id = data.id;
      this.salary.name = data.name;
      this.salary.basicSalary = data.basicSalary;
      this.salary.trafficSalary = data.trafficSalary;
      this.salary.lunchSalary = data.lunchSalary;
      this.salary.bonus = data.bonus;
      this.salary.pensionPer = data.pensionPer;
      this.salary.pensionBase = data.pensionBase;
      this.salary.medicalPer = data.medicalPer;
      this.salary.medicalBase = data.medicalBase;
      this.salary.accumulationFundPer = data.accumulationFundPer;
      this.salary.accumulationFundBase = data.accumulationFundBase;
    },
    deleteSal(data){
      this.$confirm('此操作将永久删除['+data.name+']工资账套, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
          }).then(() => {
            this.deleteRequest('/salary/sob/' + data.id).then(res=>{
                    if(res){
                      this.initSalaries();
                    }
                });
            }).catch(() => {
          this.$message({
              type: 'info',
              message: '已取消删除'
          });          
      });
    },
    nextStep(){
      if(this.activeItemIndex == 10){
        if(this.salary.id){
          this.putRequest('/salary/sob/',this.salary).then(res=>{
            if(res){
              this.dialogVisible = false;
              this.initSalaries();
            }
          })
        }else{
          this.postRequest('/salary/sob/',this.salary).then(res=>{
            if(res){
              this.dialogVisible = false;
              this.initSalaries();
            }
          });
        }
        return;
      }
      this.activeItemIndex++;
    },
    preStep(){
      if(this.activeItemIndex == 0){
        return;
      }else if(this.activeItemIndex == 10){
        this.dialogVisible = false;
        return;
      }else{
        this.activeItemIndex--;
      }
    },
    initSalaries(){
      this.loading = true;
      this.getRequest('/salary/sob/').then(res=>{
        if(res){
          this.salaries = res;
          this.loading = false;
        }
      });
    },
    showAddSalaryView(){
      this.dialogTitle = '添加工资账套',
      this.salary = {
        name:'',
        basicSalary:0,
        trafficSalary:0,
        lunchSalary:0,
        bonus:0,
        pensionPer:0,
        pensionBase:0,
        medicalPer:0,
        medicalBase:0,
        accumulationFundPer:0,
        accumulationFundBase:0
      };
      this.activeItemIndex = 0;
      this.dialogVisible = true;
    }
  }
}
</script>

<style scoped>
  .SalBtn{
    display: flex;
    justify-content: space-between;
  }
  .showAddSal{
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
</style>