<template>
  <div class="SalSobCfg">
    <el-table
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)" 
      :data="emps"
      border
      stripe>
      <el-table-column type="selection" align="center" width="55"></el-table-column>
      <el-table-column prop="name" label="姓名" align="center" width="120" fixed="left"></el-table-column>
      <el-table-column prop="workID" label="工号" align="center" width="120"></el-table-column>
      <el-table-column prop="email" label="电子邮箱" align="center" width="200"></el-table-column>
      <el-table-column prop="phone" label="电话号码" align="center" width="120"></el-table-column>
      <el-table-column prop="department.name" label="所属部门" align="center" width="120"></el-table-column>
      <el-table-column label="工资账套" align="center">
        <template slot-scope="scope">
          <el-tooltip placement="right" v-if="scope.row.salary">
            <div slot="content">
              <table>
                <tr>
                  <td>基本工资</td>
                  <td>{{scope.row.salary.basicSalary}}</td>
                </tr>
                <tr>
                  <td>交通补助</td>
                  <td>{{scope.row.salary.trafficSalary}}</td>
                </tr>
                <tr>
                  <td>午餐补助</td>
                  <td>{{scope.row.salary.lunchSalary}}</td>
                </tr>
                <tr>
                  <td>奖金</td>
                  <td>{{scope.row.salary.bonus}}</td>
                </tr>
                <tr>
                  <td>养老金比率</td>
                  <td>{{scope.row.salary.pensionPer}}</td>
                </tr>
                <tr>
                  <td>养老金基数</td>
                  <td>{{scope.row.salary.pensionBase}}</td>
                </tr>
                <tr>
                  <td>医疗保险比率</td>
                  <td>{{scope.row.salary.medicalPer}}</td>
                </tr>
                <tr>
                  <td>医疗保险基数</td>
                  <td>{{scope.row.salary.medicalBase}}</td>
                </tr>
                <tr>
                  <td>公积金比率</td>
                  <td>{{scope.row.salary.accumulationFundPer}}</td>
                </tr>
                <tr>
                  <td>公积金基数</td>
                  <td>{{scope.row.salary.accumulationFundBase}}</td>
                </tr>
              </table>
            </div>
            <el-tag>{{scope.row.salary.name}}</el-tag>           
          </el-tooltip>  
          <el-tag v-else>暂未设置</el-tag>                
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" fixed="right">
        <template slot-scope="scope">
          <el-popover
            placement="left"
            title="编辑工资账套"
            width="200"
            @show="showPop(scope.row.salary)"
            @hide="hidePop(scope.row)"
            trigger="click">
            <div>
              <el-select v-model="currentSalary" size="mini" placeholder="请选择">
                <el-option
                  v-for="item in salaries"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </div>
            <el-button slot="reference" type="danger">修改工资账套</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <div class="paging">
      <el-pagination
        background
        layout="sizes, prev, pager, next, jumper, ->, total, slot"
        :total="total"
        @current-change="currentChange"
        @size-change="sizeChange">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SalSobCfg',
  data () {
    return {
      salaries:[],
      currentSalary:null,
      loading:false,
      emps:[],
      currentPage:1,
      size:10,
      total:-1
    };
  },
  mounted(){
    this.initEmps();
    this.initSalaries();
  },
  methods: {
    hidePop(data){
      if(this.currentSalary && this.currentSalary != data.salary.id){
        this.putRequest('/salary/sobcfg/?eid='+data.id+'&sid='+this.currentSalary).then(res=>{
          if(res){
            this.initEmps();
          }
        });
      }
    },
    showPop(data){
      if(data){
        this.currentSalary = data.id;
      }else{
        this.currentSalary = null;
      }
    },
    initSalaries(){
      this.getRequest('/salary/sobcfg/salaries').then(res=>{
        if(res){
          this.salaries = res;
        }
      });
    },
    currentChange(currentPage){
      this.currentPage = currentPage;
      this.initEmps();
    },
    sizeChange(pageSize){
      this.size = pageSize;
      this.initEmps();
    },
    initEmps(){
      this.loading = true;
      this.getRequest('/salary/sobcfg/?currentPage='+this.currentPage+'&size='+this.size).then(res=>{
        if(res){
          this.emps = res.data;
          this.total = res.total;
          this.loading = false;
        }
      });
    }
  }
}
</script>

<style scoped>
  .paging{
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
</style>