<template>
  <div class="SalTable">
    工资表管理
  </div>
</template>

<script>
export default {
  name: 'SalTable',
  data () {
    return {

    };
  },
  methods: {}
}
</script>

<style scoped>

</style>