<template>
  <div class="SalSearch">
    工资表查询
  </div>
</template>

<script>
export default {
  name: 'SalSearch',
  data () {
    return {

    };
  },
  methods: {}
}
</script>

<style scoped>

</style>